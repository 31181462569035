html, body, div, span, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, em, img, q, small, strike, strong, sub, sup,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
input, textarea, button,
menu, nav, output, section, summary, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
}

a {
	color: inherit;
	text-decoration: none;
}
