:global .footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 36px;
}

.finance {
	display: block;
	width: 220px;
	color: var(--color-accent);
	border: 3px solid currentColor;
	border-radius: 7px;
	font-weight: 600;
	line-height: 36px;
	text-align: center;

	@media (--desktop) {
		position: fixed;
		left: -92px;
		top: 35%;
		transform: rotate(-90deg);
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		text-transform: lowercase;
	}

	&:hover {
		color: #fff;
		background-color: var(--color-accent);
		border-color: var(--color-accent);
	}
}

.links {
	margin-top: 32px;
	list-style: none;
	font-size: 16px;
	text-align: center;

	@media (--desktop) {
		display: flex;
	}

	&[hidden] {
		display: none;
	}

	li + li {
		@media (--mobile) {
			margin-top: 16px;
		}

		@media (--desktop) {
			margin-left: 16px;
		}
	}

	a:hover {
		color: var(--color-accent);
	}
}

.logo {
	display: block;
	max-width: 240px;
	height: auto;
	margin-left: auto;
}
