@import 'fonts.css';
@import 'reset.css';

:root {
	--color-accent: #000;
	--color-accent-extra: #da3a32;
	--color-accent-filter: none;
}

html {
	min-height: 100vh;
	box-sizing: border-box;
	background-color: #fff;
	background-image: url('assets/grid.svg');
	background-position: center top;
	color: #000;
	font-family: 'Helventica Neue', 'Helvetica', 'Arial', 'Roboto', sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 1.5;
}

body {
	max-width: 1325px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 24px;
	padding-right: 24px;

	@media (--desktop) {
		padding-left: 144px;
		padding-right: 144px;
	}
}

*,
*::before,
*::after {
	box-sizing: inherit;
}
